/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDashBoardData } from '../../store/reducers/userData/apiThunk';
import { fetchLogout } from '../../store/reducers/authentication/apiThunk';
import { ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import './style.css';
// import { CSmartPagination } from '@coreui/react-pro';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/route';
const { LANDING } = ROUTE_PATH;

const limit = 1000;

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userData, setUserData] = useState([]);
  const [activeTable, setActiveTable] = useState('landings');
  const [searchValue, setSearchValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const DataEnums = {
    'Landings': 1,
    'Salesforce': 2,
    'Talks': 3,
    'Enquiries': 4,
    'Contact': 5,
    'Carrer': 6
  }
  const fetchDashBoardDetails = async (tableType,isDownload = false,option = null) => {
    let requestData = {
      type: tableType,
      page: activePage,
      limit: limit,
      search: searchValue ? searchValue : ''
    }
    const response = await dispatch(fetchDashBoardData(requestData)).then((val) => val);
    const { payload: { data = {}, status = '' } = {} } = response;
    if (status) {
      setTotalPages(data?.pagination?.pages);
      setUserData(response.payload.data);
      if (isDownload) {
        const  file  = response.payload.file;
        const fileName = `${option}.csv`
        const link = document.createElement('a');
        link.href = `data:text/csv;base64,${file}`;
        link.download = fileName;
        link.click();
      }
    }
  };

  useEffect(() => {
    switch (activeTable) {
      case 'landings':
        fetchDashBoardDetails(DataEnums.Landings);
        break;
      case 'salesforce':
        fetchDashBoardDetails(DataEnums.Salesforce);
        break;
      case 'talks':
        fetchDashBoardDetails(DataEnums.Talks);
        break;
      case 'enquiries':
        fetchDashBoardDetails(DataEnums.Enquiries);
        break;
      case 'contact':
        fetchDashBoardDetails(DataEnums.Contact);
        break;
      case 'careers':
        fetchDashBoardDetails(DataEnums.Career);
        break;
      default:
        break;
    }
  }, [activePage, activeTable, searchValue]);

  const showTable = (tableId) => {
    setActiveTable(tableId);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDownload = (option) => {
    fetchDashBoardDetails(DataEnums[option], true,option);
  };

  const handleLogout = async () => {
    const data = await dispatch(fetchLogout());
    if(data){
      ToastNotifySuccess('Logged Out Successfully');
      navigate(LANDING);
    }
  };

  return (
    <div>
      <header className="header">
        <div className="logo">
          <img src="https://d2e3cmtjkfw2a3.cloudfront.net/static/media/logo/dianapps_logo.svg" alt="dianapps_logo" />
        </div>
        <div className="header-actions">
          <input className="search" type="search" placeholder="search here" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}></input>
          <div className="dropdown">
            <button className="dropbtn" onClick={toggleDropdown}>
              CSV <span className="arrow">&#x25BC;</span>
            </button>
            {dropdownOpen && (
              <div className="dropdown-content">
                <button onClick={() => handleDownload('Landings')}>Landings</button>
                <button onClick={() => handleDownload('Salesforce')}>Salesforce</button>
                <button onClick={() => handleDownload('Talks')}>Talks</button>
                <button onClick={() => handleDownload('Enquiries')}>Enquiries</button>
                <button onClick={() => handleDownload('Contact')}>Contact</button>
              </div>
            )}
          </div>
          <button className="logout-button" onClick={handleLogout}>Logout</button>
        </div>
      </header>
      <nav className="navbar">
        <ul className="nav-list">
          <li className="nav-item"><button onClick={() => showTable('landings')}>Landings</button></li>
          <li className="nav-item"><button onClick={() => showTable('salesforce')}>Salesforce Get In Touch</button></li>
          <li className="nav-item"><button onClick={() => showTable('talks')}>Talks</button></li>
          <li className="nav-item"><button onClick={() => showTable('contact')}>Contact</button></li>
          <li className="nav-item"><button onClick={() => showTable('enquiries')}>Enquiries</button></li>
        </ul>
      </nav>
      <div id="content">
        {activeTable === 'landings' && (
          <table className="data-table">
            <thead>
              <tr><th>Name</th><th>Email</th><th>Message</th><th>Phone</th><th>Country Code</th><th>Date</th></tr>
            </thead>
            <tbody>
              {userData && userData.map((data, index) => (
                <tr key={index}>
                  <td>{data.name ? data.name : '-'}</td>
                  <td>{data.email ? data.email : '-'}</td>
                  <td>{data.message ? data.message : '-'}</td>
                  <td>{data.phone ? data.phone : '-'}</td>
                  <td>{data.countryCode ? data.countryCode : '-'}</td>
                  <td>{data.createdAt ? new Date(data.createdAt).toLocaleDateString() : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {activeTable === 'salesforce' && (
          <table className="data-table">
            <thead>
              <tr><th>Name</th><th>Company Name</th><th>Company Email</th><th>Date</th></tr>
            </thead>
            <tbody>
              {userData && userData.map((data, index) => (
                <tr key={index}>
                  <td>{data.fullName ? data.fullName : '-'}</td>
                  <td>{data.companyName ? data.companyName : '-'}</td>
                  <td>{data.companyEmail ? data.companyEmail : '-'}</td>
                  <td>{data.createdAt ? new Date(data.createdAt).toLocaleDateString() : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {activeTable === 'talks' && (
          <table className="data-table">
            <thead>
              <tr><th>Name</th><th>Email</th><th>Message</th><th>Phone</th><th>Country Code</th><th>Type</th><th>Date</th></tr>
            </thead>
            <tbody>
              {userData && userData.map((data, index) => (
                <tr key={index}>
                  <td>{data.name ? data.name : '-'}</td>
                  <td>{data.email ? data.email : '-'}</td>
                  <td>{data.message ? data.message : '-'}</td>
                  <td>{data.phone ? data.phone : '-'}</td>
                  <td>{data.countryCode ? data.countryCode : '-'}</td>
                  <td>{data.type ? data.type : '-'}</td>
                  <td>{data.createdAt ? new Date(data.createdAt).toLocaleDateString() : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {activeTable === 'enquiries' && (
          <table className="data-table">
            <thead>
              <tr><th>Name</th><th>Email</th><th>Message</th><th>Company Name</th><th>Phone</th><th>Country Code</th><th>When to start</th><th>Date</th></tr>
            </thead>
            <tbody>
              {userData && userData.map((data, index) => (
                <tr key={index}>
                  <td>{data.name ? data.name : '-'}</td>
                  <td>{data.email ? data.email : '-'}</td>
                  <td>{data.message ? data.message : '-'}</td>
                  <td>{data.company ? data.company : '-'}</td>
                  <td>{data.phone ? data.phone : '-'}</td>
                  <td>{data.countryCode ? data.countryCode : '-'}</td>
                  <td>{data.whenToStart ? data.whenToStart : '-'}</td>
                  <td>{data.createdAt ? new Date(data.createdAt).toLocaleDateString() : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {activeTable === 'contact' && (
          <table className="data-table">
            <thead>
              <tr><th>Name</th><th>Email</th><th>Message</th><th>Phone</th><th>Country Code</th><th>Date</th></tr>
            </thead>
            <tbody>
              {userData && userData.map((data, index) => (
                <tr key={index}>
                  <td>{data.name ? data.name : '-'}</td>
                  <td>{data.email ? data.email : '-'}</td>
                  <td>{data.message ? data.message : '-'}</td>
                  <td>{data.phone ? data.phone : '-'}</td>
                  <td>{data.countryCode ? data.countryCode : '-'}</td>
                  <td>{data.createdAt ? new Date(data.createdAt).toLocaleDateString() : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
