/* eslint-disable import/prefer-default-export */
const REGEX = {
    EMAIL_PATTERN: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w{2,4}([-.]\w+)*$/,
    PASSWORD_PATTERN:
      /(?=[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])(?=.{8,16}).*$/, // Passwords must be Include at least 1 lowercase letter, 1 capital letter, 1 number,  1 special character => !@#$%^&*
    USERNAME_PATTERN: /^[a-zA-Z][a-zA-Z0-9_-]{2,20}$/,
    FIRSTNAME_PATTERN: /^[a-zA-Z0-9\s]+$/,
    URL_PATTERN: /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/,
  
  };
  
  const CONSTANTS = {
  };
  
  export {
    REGEX,
    CONSTANTS,
  };
  