import { createBrowserRouter } from 'react-router-dom';
import { ROUTE_PATH } from '../constants/route';
import LoginPage from '../pages/login';
import DashboardPage from '../pages/dashboard/DashboardLayout';
import PrivateRoute from './privateRoute';

const routes = createBrowserRouter([
  {
    path: ROUTE_PATH.LOGIN,
    element: <LoginPage />,
  },
  {
    path: ROUTE_PATH.DASHBOARD,
    element: <PrivateRoute><DashboardPage /></PrivateRoute>,
  },
]);

export default routes;
