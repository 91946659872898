import React from 'react';
import Dashboard from './index';

const DashboardLayout = () => {
  return (
    <div>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <div className="body flex-grow-1 px-3">
          <Dashboard />
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  );
};

export default DashboardLayout;
