const ROUTE_PATH = {
    PAGE404: '*',
    LANDING: '/',
    LOGIN: '/',
    DASHBOARD: '/dashboard',
  };

const API_VERSION = {
  V1: '/api',
};

const API_PATH = {
  LOGIN: `${API_VERSION.V1}/admin/login`,
  LOGOUT: `${API_VERSION.V1}/admin/logout`,
  DASHBOARD: `${API_VERSION.V1}/admin/get-data`,
};
  export { ROUTE_PATH , API_PATH};