import React, { useState } from 'react';
import { REGEX } from '../../constants/constants';
import { fetchLogin } from '../../store/reducers/authentication/apiThunk';
import { useDispatch } from 'react-redux';
import { ToastNotifyError,ToastNotifySuccess } from '../../components/Toast/ToastNotify';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/route';
import './style.css';
const { DASHBOARD } = ROUTE_PATH;

const Login = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleSubmit = async (event) => {
    if (
        email.match(REGEX.EMAIL_PATTERN) && password
    ) {
        event.preventDefault();
        setValidated(false);
        const requestData = {
        "email": email,
        "password": password
        }
        const data = await dispatch(fetchLogin(requestData));
        const { payload } = data
        if (payload?.status) {
        ToastNotifySuccess('Logged In Successfully')
        navigate(DASHBOARD);
        }
    } else {
        ToastNotifyError('Please enter login credentials');
    }
    };

    return (
        <div className="bg-color min-vh-100 d-flex flex-row align-items-center">
            <div className='main-Div'>
                <div className='login-box'>
                    <h1 style={{ fontWeight: 'bold', color: 'black' }}>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <div className='email-box'>
                        <input type='email' placeholder='Email' autoComplete='email' value={email}
                         onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    <div className='password-box'>
                        <input 
                        type='password'
                         placeholder='Password'
                         autoComplete='current-password'
                         value={password}
                         onChange={(e)=>setPassword(e.target.value)}
                         />
                    </div>
                    <div className='login-button'>
                        <button className="btn btn-primary" onClick={handleSubmit}>Login</button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Login;
